.container-card {
    max-width: 38rem !important;
    height: 280px;
    border-radius: 5px !important;
    background: #f5f5f5;
    padding: 12px 20px;
    display: flex;
    flex-direction: column;
    gap: 2.0rem;

    justify-content: space-between;
}

.content-card {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.6rem;
}


.terminal-info-content {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;

    border-right: 2px solid rgb(209, 208, 208);

    span {
        color: #002f6c;
        font-weight: 900;
        text-transform: uppercase;
        font-size: 1.6rem;
    }

    p {
        text-transform: uppercase;
        font-size: 14px;
    }
}

.terminal-status-content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding-left: 10px;
}

.terminal-status-info-container {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;

    span {
        color: #002f6c;
        font-weight: 900;
        text-transform: capitalize;
        font-size: 1.12rem;
    }
}

.terminal-status-info {
    background: rgb(209, 208, 208);
    padding: 4px 20px;
    display: flex;
    gap: 0.6rem;
    align-items: center;
    border-radius: 20px;

    span {
        color: black;
        font-weight: 900;
        text-transform: capitalize;
    }
}

.terminal-status-disruption {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.disruption-info {
    display: flex;
    flex-direction: column;
    padding: 10px;

    span {
        font-weight: bold;
    }
}

.disruption-info:first-of-type {
    border-right: 2px solid rgb(209, 208, 208);
}

.actions-container {
    display: flex;
    justify-content: space-between;

    button {
        width: 160px;
        padding: 6px;
        font-size: 16px;
        font-weight: bold;
        border-radius: 5px;

        transition: background ease 0.9s;
    }
}

@media (min-resolution: 120dpi) and (max-resolution: 144dpi) {
    .terminal-info-content span {
        font-size: 1.4rem;;
    }
    .actions-container button {
        width: 120px;
        font-size: 14px;
    }
}

@media (min-resolution: 144dpi) {
    .terminal-info-content span {
        font-size: 1.2rem;;
    }

    .terminal-status-info span {
        font-size: 12px;
    }

    .actions-container button {
        width: 100px; 
        font-size: 12px;
    }
}

.main-btn {
    background: #002f6c;
    color: white;
    border: none;

    &:hover {
        background: white;
        border: 1px solid black;
        color: #002f6c;
    }
}

.secondary-btn {
    border: 1px solid black;
    color: #002f6c;

    &:hover {
        background: #002f6c;
        color: white;
        border: none;
    }
}